@import "/src/assets/scss/global.scss";

.greenEnabled{
	color: $green;
}

.redBlocked{
	color: $redControlloAccessi;
}

.orangeDisabled{
	color: $orangeControlloAccessi;
}

.operators__item_element__single .button-controllo-accessi.greenEnabledButton {
	background-color: $green;
	border-color: $green;
	color: $white;
}

.operators__item_element__single .button-controllo-accessi.redBlockedButton {
	background-color: $redControlloAccessi;
	border-color: $redControlloAccessi;
	color: $white;
}

.operators__item_element__single .button-controllo-accessi.orangeDisabledButton{
	background-color: $orangeControlloAccessi;
	border-color: $orangeControlloAccessi;
	color: $white;
}

.operators__item_element__single .button-controllo-accessi.greyDisabledButton{
	background-color: $mid-high-grey;
	border-color: $mid-high-grey;
	color: $white;
}


.operators__item_element__single .button-controllo-accessi.greenEnabledButton:active {
	background-color: $green;
	border-color: $green;
	color: $white;
}

.operators__item_element__single .button-controllo-accessi.redBlockedButton:active {
	background-color: $redControlloAccessi;
	border-color: $redControlloAccessi;
	color: $white;
}

.operators__item_element__single .button-controllo-accessi.orangeDisabledButton:active {
	background-color: $orangeControlloAccessi;
	border-color: $orangeControlloAccessi;
	color: $white;
}

.operators__item_element__single .button-controllo-accessi.greyDisabledButton:active {
	background-color: $mid-high-grey;
	border-color: $mid-high-grey;
	color: $white;
}

.button-controllo-accessi{
	width: 180px;
}

@include media-breakpoint-between(md, lg) {
	.button-controllo-accessi.btn{
		font-size: 12px;
	}
}

@include media-breakpoint-between(xs, md) {
	.button-controllo-accessi.btn{
		font-size: 10px;
	}
}