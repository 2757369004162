@import '/src/assets/scss/global.scss';

.module-state {
	display: flex;
	justify-content: center;
	align-items: center;
	width: $module-state-width;
	min-height: 50px;
	background-color: $light-grey;
	border: solid 1px $border-grey;
	border-radius: 7px;
	margin-bottom: 10px;
	box-shadow: 1px 2px 6px 1px $module-list-item-shadow;

	.date-time {
		color: $font-black;
	}

	.status-start,
	.status-read,
	.status-in-progress,
	.status-draft {
		display: flex;
		justify-content: space-around;
		border: solid 1px $light-grey;
		border-radius: 7px;
		font-family: $headings-font-family;
		font-size: $font-size-sm;
		text-align: center;
		align-items: center;
		margin: 10px 0;
		max-width: 70px !important;
		height: 30px;
		cursor: pointer;
		color: $font-black;
	}

	.status-start {
		background-color: $green;
		border-color: $green;
		color: $white;
		height: $min-tappable-area;
		max-width: 2.5*$min-tappable-area !important;
	}

	.status-read,
	.status-in-progress {
		background-color: $mid-low-grey;
		border-color: $mid-low-grey;
	}

	.status-draft {
		background-color: $yellow;
		border-color: $yellow;
	}

	@include media-breakpoint-up (xxl) {
		width: $module-state-width-xl;
		min-height: 70px;

		.date-time {
			font-size: $font-size-md;
		}
	}
}

/*Incident*/

.module-state--incident {
	height: 70px;
	justify-content: space-around;
}

.row.font-b-incident {
	display: inline;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: $font-size-sm;
	max-width: 100%;
	@include bold-text;
	margin: 0;
}