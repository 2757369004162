@import "/src/assets/scss/global.scss";
.key-items-page-container {
	@include module-items-page-container;
	min-height: $min-heigth-container;
}

.standard_select_width {
	width: 60%;
}

.print-full-section {
	page-break-inside: avoid;
}

@include media-breakpoint-down(md){
	.standard_select_width {
		width: 85%;
	}
}