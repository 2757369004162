@import '/src/assets/scss/global.scss';

.select-field-container-checklist {

	.select-field-question-checklist {
		width: 40%;


		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	.select-field-question-checklist-placeholder {
		color: $grey;
	}

	.select-field-options {
		color: var(--sicurforce-input-color);
	}
}