@import '/src/assets/scss/global.scss';

.dropdown.change-password-dropdown {
	position: static;

	.drop-down-container {
		.card {
			background-color: $bg-navbar-glass;
		}
	}

	.dropdown-menu.dropdown-menu-end {
		right: 0;
	}
}

.drop-down-container.dropdown-caret-bg:after {
	display: none;
}