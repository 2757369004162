@import '/src/assets/scss/global.scss';
@import 'react-datepicker/dist/react-datepicker';

.date-picker-group-container {
	.react-datepicker {
		display: flex;
		flex-wrap: wrap;
	}
	
	.react-datepicker__triangle {
		transform: translate3d(120px, 0px, 0px) !important;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.date-picker-container {
		width: 40%;
		padding: 5px 16px;
		height: 36px;
		font-family: $font-family-base;

		color: var(--sicurforce-input-color);
		background-color: var(--sicurforce-input-bg);
		border: 1px solid var(--sicurforce-input-border-color);
		border-radius: .25rem;

		
		&:focus{
			border-color: var(--sicurforce-input-focus-border-color);
			outline: 0;
			box-shadow: 0 0 0 0.25rem $box-shadow-bootstrap-input-filds;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}

		@include media-breakpoint-down(sm){
			width: 100%;
		}

		&::placeholder{
			font-family: $font-family-base;
		}
	}

	.feedback-message{
		width: 100%;
		margin-top: 0.25rem;
		padding-left: 0;
		font-size: 75%;
		color: #e63757;
	}
}