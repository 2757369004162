@import '/src/assets/scss/global.scss';

.accordion-details {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.text{
	font-weight: 500;
	margin-left: 20px;
}

.text-left{
	font-weight: 500;
}

.container-large{
	height: 100%;
	padding: 0;
}

.button_details{
    width: 180px;
}

@include media-breakpoint-between(xs, md){
	.container-large{
		width: 100%;
		height: 100%;
		padding: 0;
		
	}

	.text{
		margin-left: 0px;
	}
}

@include media-breakpoint-down(md) {
    
	.text{
		margin-left: 0px;
	}

	svg {
		margin-right: 0rem;
	}
	
  }