@import '/src/assets/scss/global.scss';

.dettaglio-controllo-accessi-container{
	min-height: $min-heigth-container;
	max-width: 1250px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding: 32px 16px;
}

.dettaglio-controllo-accessi-box {
	display: flex;
	flex-wrap: wrap;
	background: $gray-550;
	border-radius: 10px;
	border: solid 1px $gray-f5;
	box-shadow: 1px 2px 2px 1px $grey;
	font-size: 0.8rem;
	height: 100%;
}

.dettaglio-controllo-accessi-box_item {
	display: flex;
	flex-direction: column;
	width: calc(33.33% - 20px);
	margin: 10px;
	justify-content: flex-start;
}

.controlloAccessiDettaglioSegnalazione__button__dettaglio{
	width: 230px;
	height: 40px;
	padding: 0;
}

.controlloAccessiDettaglioSegnalazione_buttons {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 768px) {
	.dettaglio-controllo-accessi-box_item {
		width: calc(50% - 20px);
	}
}

@media screen and (max-width: 576px) {
	.dettaglio-controllo-accessi-container{
		padding: 8px;
	}

	.dettaglio-controllo-accessi-box {
		flex-direction: column;
	}

	.dettaglio-controllo-accessi-box_item {
		flex-direction: row;
	}

	.text-mobile {
		margin-left: 8px;
		font-size: 14px;
	}

	.label-mobile {
		font-size: 14px;
	}
}