@import '/src/assets/scss/global.scss';

.patrol-read-item-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	border: solid 1px $light-grey;
	background-color: $mid-grey;
	width: $patrol-read-cards-container-width;
	border-radius: 11px;
	box-shadow: 1px 2px 0 1px $mid-low-grey;
	padding: 0.3rem 1rem;

	.patrol-read-item-title-date {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	
	.patrol-read-item-title-subtitle-container {
		flex-grow: 1;
		width: 0;
		
		.patrol-read-item-title {
			color: $font-black;
			font-size: $font-size-md;
			@include bold-text;
			overflow: hidden;

			&.red {
				color: $warning-red
			}

			&.green {
				color: $green
			}
		}

		.patrol-read-item-subtitle {
			color: $dark-grey;
			font-size: $font-size-sm;
		}
	}

	.patrol-read-item-title-date-container {
		display: flex;
		align-items: center;

		.patrol-read-item-title-date {
			color: $dark-grey;
			font-size: $font-size-sm;
		}

		.patrol-read-item-title-img {
			flex-shrink: 0;
		  }
	}

	@include media-breakpoint-up(xxl) {
		width: $patrol-read-cards-container-width-xl;
	}
}