@import '/src/assets/scss/global.scss';

.radio-question-and-buttons-container {
	width: 100%;
	.input-group {
		.question-container {
			display: flex;
			flex-wrap: wrap;
			flex-grow: 1;
			.radio-button {
				margin-left: 0;
			}

			.form-check-label {
				padding-inline: 1.2rem;
				@include media-breakpoint-down(sm){
					padding-inline: 0.7rem;
				}
			}
		}
	}

	.radio-buttons-container {
		display: flex;
		padding-left: 0px;

		.radio-button {
			width: fit-content;
			padding: 0;

			.form-check-label {
				font-size: $font-size-sm;
				@include bold-text-labels;
				font-family: $font-family-base;
			}
		}
	}
	
	.feedback-message {
		width: 100%;
		margin-top: 0.25rem;
		padding-left: 0;
		font-size: 75%;
		color: #e63757;
	}
}