@import '/src/assets/scss/global.scss';

.show-icons {
    cursor: pointer;
}

.icons-container {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    &.open {
        max-height: 300px;
        transition: max-height 0.5s ease-in;
    }

    svg {
        cursor: pointer;
    }
}