@import '/src/assets/scss/global.scss';

.controllo-accessi-dettaglio .dati-tessera div {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	padding-bottom: 0.3rem;
}


@include media-breakpoint-up(lg) {
	.controllo-accessi-dettaglio .dati-tessera div {
		flex-basis: calc(100% / 3);
	}
}
