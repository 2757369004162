@import '/src/assets/scss/global.scss';

.turni-box {
    @include shadow-rounded-box;
    background-color: $mid-grey;
    color: $datetime-color;
    font-size: $font-size-sm;
    height: 100%;

    .turni-type .card-turni-type {
        @include blue-text;
        @include bold-text;
        font-size: $font-size-xl;
        padding: 2px 0;
        max-width: $min-tappable-area*1.5;
    }

	.label-turni{
		font-size: 13px;
	}

	.day--turni {
		display: inline-flex;
	}

    .turni-time-container {
        margin: 10px auto;
        padding-left: 0px;
        font-family: $font-family-base;
    }

    .turni-details {
        padding: 5px;
        text-transform: capitalize;
    }

    .client-name {
        font-size: $font-size-md;
        text-transform: capitalize;
    }
}

@include media-breakpoint-up(md) {  
    .turni-box .turni-details {
        padding: 10px 5px;
    }
}