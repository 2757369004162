@import '/src/assets/scss/global.scss';

.turni-container {
    @include container;
	min-height: $min-heigth-container;
}

.empty-page-note {
    display: flex;
    justify-content: center;
    min-height: 2*$min-tappable-area;
	width: fit-content;
}