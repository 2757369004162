@import "/src/assets/scss/global.scss";


.key-items-page-container {
	@include module-items-page-container;
	min-height: $min-heigth-container;
}

.key-consultation-box {
	font-family: $headings-font-family;
}

.key-sblocca-button-container{
	margin: 0 auto;
}

.key-sblocca-button{
	width: 230px;
	height: 40px;
	padding: 0;
}

@include media-breakpoint-down(xxl){
	.key-sblocca-button{
		width: 22%;
	}
}

@include media-breakpoint-down(lg){
	.key-sblocca-button{
		width: 100%;
	}
}

