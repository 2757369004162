@import '/src/assets/scss/global.scss';

.audit-page-container {
	.begin-finish-shift-button {
		border-radius: 6px;
		width: 130px;
		height: 38px;
		font-family: $font-family-base;
	}
}

.delete-prop{
	color: $primary;
	text-decoration: underline;
	font-size: $font-size-lg;
	cursor: pointer;
	font-weight: bold;
}