@import '/src/assets/scss/global.scss';

.turno-container {
	min-height: $min-heigth-container;
}

.opeator-title{
	font-size: 18px;
	margin-bottom: 4px;
}

.opeator-value{
	color: $black;
	font-size: 20px;
	font-weight: 400;
}

.red-text{
	color: $red;
}