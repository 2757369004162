@import '/src/assets/scss/global.scss';

.previewJornal{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
	background-color: black;
}

.JournalePreviewImage{
	height: 100%;
	width: 300px;
}

.download_img_btn {
	border-radius: 50%;
	width: $min-tappable-area;
	height: $min-tappable-area;
	background-color: $dark-grey;
	color: #fff;
	border: none;
	cursor: pointer;
}

.download_img_btn:hover {
    background-color: #000;
	opacity: 0.75;
}

.modal_header_container {
	display: flex;
	width: 100%;
	justify-content: end;
}
