@import '/src/assets/scss/global.scss';

.coordinatore-container {
    @include container;
	min-height: $min-heigth-container;
}

.select-element{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    & .select-element-content {
        min-width: 350px;
    }
}

.submit-and-draft-buttons{
    width: 100px;
    height: 40px;
    font-family: $font-family-base;
    align-items: center;

    &:disabled {
        pointer-events: unset;
        cursor: not-allowed;
    }
}

.button-content{
    display: flex;
    justify-content: center;
    .submit-button {
        min-width: 150px;
    }
}

.select-field-question {
    width: 40%;
}

.customer-selection-label,
.customer-address-selection-label  {
    color: $dark-grey;
}

.select-field-options {
    color: $font-black;
}