@import '/src/assets/scss/global.scss';

.printable_page {
    @media print {
        #downloadPdf, #squares_menu, #logout, #username, .app-version, header, footer, .key-sblocca-button {
            display: none;
        }

        #footer {
            margin-top: calc(2 * $padding-12);
        }

        .images_preview, .video_preview {
            display: block;
        }

        .px-5 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }

        .module-page-title-title-component-container .module-page-title-title-component {
            color: $blue;
            font-size: larger;
            margin-left: calc(-2 * $padding-12);
			background-color: $gray-550;
        }
        
        .report-section-container {
            .report-questions-container {
                .report-answer {
                    margin: 0;
                }
            }
        }

        .day.bold-text {
            color: $blue;
        }

        .print-blue-text {
            color: $blue;
        }

        .print-title {
            background-color: $gray-550;
			box-shadow: 1px 2px 2px 1px $grey;
            color: $blue;
        }
     }
}