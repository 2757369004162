@import '/src/assets/scss/global.scss';

.col.patrol-item-column {
	@include modules-item;
}

.patrol-item-container {
	@include grey-rounded-card;
	@include shadow-rounded-box;
	background-color: $light-grey;
	width: 100%;
	min-height: 2*$min-tappable-area;
	height: 100%;

	.patrol-title {
		font-size: $font-size-lg;
		justify-content: center;	
	}
}

.date-time {
	color: $mid-high-grey;
	font-size: $font-size-sm;
}

@include media-breakpoint-up(md) {
	.patrol-title {
		font-size: $font-size-xl;	
	}
}