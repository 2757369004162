@import '/src/assets/scss/global.scss';

.main-layout-container .navbar-vertical {
    box-shadow: 0 0 10px 0 $grey;

    .dropdown-indicator {
        color: $primary !important;
        &.active {
            @include bold-text;
        }
    }

    .toggle-icon-wrapper {
        margin: 0;
        padding: 0;
        width: $min-tappable-area;

        &.hidden > button {
            opacity: 0;
            pointer-events: none;
        }
    }

	.logout-text{
		position: relative;
		top: -15px;
		right: 3px;
	}

	.nav-logout{
		display: block;
		height: 50px;
		margin-right: 125px;
	}

    .sidebar-menu-end {
        background-color: transparent;
    }

    .navbar-brand {
        height: 2*$min-tappable-area;
    }
    
}

.sidebar-menu-row {
    height: $min-tappable-area;
    line-height: $min-tappable-area;
}

.toggle-icon-wrapper .navbar-toggler-humburger-icon:hover {
    background-color: transparent;
}


@include media-breakpoint-up(xl) {
    .main-layout-container .navbar-vertical {
        box-shadow: none;
        .burger-container {
            height: 2*$min-tappable-area;
        }
    }
    .navbar-collapse.collapse {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}