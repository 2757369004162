@import '/src/assets/scss/global.scss';

.container.patrol-details-page-container {
	margin-block: $min-tappable-area;
	max-width: 92%;
	margin-inline: auto;

	.patrol-read-cards-and-notes-container {
		display: flex;
		flex-wrap: wrap;
		row-gap: 3rem;
		column-gap: 1rem;
		justify-content: space-between;
		margin-block: $min-tappable-area;
		justify-content: flex-start;
	}

	.patrol-read-notes-container {
		font-size: $font-size-md;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.patrol-read-notes-title{
		margin-bottom: 1rem;
		display: inline-block;
		width: $patrol-read-cards-container-width;
	}

	.patrol-read-notes-body {
		min-height: 5rem;
		width: $patrol-read-cards-container-width;
		border: 1px solid $blue;
		border-radius: 8px;
		padding: 1rem;
	}

	.patrol-read-cards-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}

	@include media-breakpoint-up(md) {
		.patrol-read-cards-container {
			align-items: start;
		}
	}

	@include media-breakpoint-up(lg) {
		max-width: 85%;
		
		.patrol-read-notes-container {
			margin-left: 2*$min-tappable-area;
		}
	}

	@include media-breakpoint-up(xxl) {
		max-width: 68%;

		.patrol-read-notes-title{
			margin-bottom: 1rem;
			display: inline-block;
			width: $patrol-read-cards-container-width-xl;
		}
	
		.patrol-read-notes-body {
			width: $patrol-read-cards-container-width-xl;
		}
		
		.patrol-read-cards-container {
			gap: 2rem;
		}
	}
}