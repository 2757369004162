@import '/src/assets/scss/global.scss';

.center-name{
	align-items: center
}

.icon-item{
	box-shadow: none !important;
}

.font-span{
	font-weight: 800;
	color: $gray-700;
}

textarea {
	resize: none !important;
}

.module-journal--page.editPage {
	padding-left: calc(var(--sicurforce-gutter-x) * 0.5) !important;
}

.journal-box-container{
	border-radius: 10px;
    box-shadow: 1px 2px 2px 1px $grey;
	background-color: $light-grey;
}

.journal-details-container {
	@include grey-rounded-card;
	@include shadow-rounded-box;
	max-width: 60%;

	@include media-breakpoint-between(xs,md){
		max-width: 100%;
	}
	@include media-breakpoint-between(md,lg){
		max-width: 90%;
	}
	@include media-breakpoint-between(lg,xlg){
		max-width: 80%;
	}
}

.journal-form-container {
	padding: 0;
	margin-bottom: 20px;
	@include black-text();

	.journal-form-buttons-container {
		display: flex;
		gap: 50px;
		@include media-breakpoint-between(lg,xlg){
			gap: 250px;
		}
		@include media-breakpoint-between(md,lg){
			gap: 150px;
		}
		@include media-breakpoint-between(sm,md){
			gap: 100px;
		}
		bottom: 20px;
	}
	.submit-and-draft-buttons{
		width: 180px;
		height: 50px;
		font-family: $font-family-base;

		&:disabled {
			pointer-events: unset;
			cursor: not-allowed;
		}
	}
}

.images_preview {
	display: none;
	max-width: $image-videos-pdf-preview-size;
	height: auto;
}

.video_preview {
	display: none;
	max-width: $image-videos-pdf-preview-size;
	height: auto;
}

.journal_centerd_button {
	text-align: end;
}