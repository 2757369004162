@import '/src/assets/scss/global.scss';

.col.checklist-item-column {
	@include modules-item;
}

.checklist-item-container {
	@include grey-rounded-card;
	@include shadow-rounded-box;
	background-color: $light-grey;
	width: 100%;
	min-height: 2*$min-tappable-area;
	height: 100%;

	.checklist-title {
		font-size: $font-size-lg;
		justify-content: center;	
	}
}
.pos-rel{
	position: relative;
}
.container-icon{
	position: absolute;
	top: 5px;
	left: -5px;
	color: $blue;
}

.date-time {
	color: $mid-high-grey;
	font-size: $font-size-sm;
}

@include media-breakpoint-up(md) {
	.checklist-title {
		font-size: $font-size-xl;	
	}
}