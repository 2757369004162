@import "/src/assets/scss/global.scss";

.element_container_input {
	display: flex;
	background: $light-grey;
	box-shadow: 2px 4px 1px 2px $grey;
	border-radius: 10px;
}

.element_container_input__input .element_container_input__input__text {
	width: 400px;
}
.element_container_input__input .element_container_input__input__text {
	border-color: $light-grey;
}
.element_container_input__input > .element_container_input__input__text {
	--sicurforce-input-bg: $light-grey;
	background-clip: none;
	box-shadow: none;
	border: 1px solid $light-grey;
}

.element_container_input__input {
	box-shadow: -1px 4px 0px 0px $light-grey;
	border-radius: 7px;
}

.submit-button.lens-search-button {
	background-color: $light-grey;
	height: 100%;
	margin-left: 5px;
}

@include media-breakpoint-between(xs, md) {
	.element_container_input__input .element_container_input__input__text {
		height: 30px !important;
		align-items: center;
	}

	.element_container_input__input .element_container_input__input__text {
		width: 150px;
	}

	.submit-button.lens-search-button {
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
