@import '/src/assets/scss/global.scss';

.shift-time-container {
	margin: 10px auto;
	padding-left: 0px;
    font-family: $headings-font-family;
    .info {
        min-height: $min-tappable-area;
    }
}

.box-details-accordion {
    .label {
        color: $mid-high-grey;
        min-width: 40%;
    }

    .datetime-box {
        .day, .hour {
            @include black-text;
        }

        .label, .day, .hour {
            display: inline-flex;
            font-size: $font-size-sm;
            @include bold-text;
            
            &.label {
                margin-right: 2px;
            }
        }
    }
    .shift-notes {
        font-size: $font-size-md;
        max-height: 185px;
        overflow-y: auto;
    }
}

.shift-box {
    justify-content: space-between; 
    align-items: center;
    cursor: pointer;
}

.blue-text {
	color: $blue;
	font-size: $font-size-md;
}

.font-500{
    font-weight: 500;
}

.item-box-margin{
	margin-bottom: 18px;;
} 

@include media-breakpoint-between(xs, md){
	.container-large{
		width: 100%;
		height: 100%;
		padding: 0;
		
	}

	.text{
		margin-right: 0px;
	}
	.item-box-margin{
		margin-bottom: 1px;;
	} 
}


