@import '/src/assets/scss/global.scss';

.navbar-top {
    height: $header-footer-height;
    justify-content: flex-end;
}

.navbar-glass.navbar-expand {
    .navbar-nav-icons {
        flex-wrap: wrap;
    }

    .toggle-icon-wrapper {
        margin: 0;
        padding: 0;
        width: $min-tappable-area;
        &.hidden > button {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.welcome-message {
    color : $blue;
    display: inline-flex;
    justify-content: flex-end;
    
    &:not(.sidebar-menu-row) {
        padding-right: 15px;
    }
}

@include media-breakpoint-up(xl) {
    .navbar-vertical.navbar-expand-xl.sicurforce + .content .navbar-top .navbar-brand {
        display: flex;
    }
}