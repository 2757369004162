@import '/src/assets/scss/global.scss';

.module-page-title-title-component-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@include black-text;
	gap: 1.5rem;
	flex-wrap: wrap;

	.module-page-title-title-component {
		@include module-page-title;
	}

	.label-color-red{
		background-color: $orange;
	}

	.module-page-title-date-and-time-container {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		font-size: $font-size-sm;
		gap: 1rem;

		.module-page-title-last-update {
			@include bold-text;
		}

		.module-page-title-date-and-time-container {
			gap: 1rem;
			flex-wrap: wrap;
			@include bold-text;

			.module-page-title-date-and-time {
				color: $blue;
			}
		}
		
		.module-page-title-status-in-progress {
			background-color: $yellow;
			border-color: $yellow;
			font-size: $font-size-sm;
			border-radius: 7px;
			width: 67px;
			height: 22px;
		}
	}

}