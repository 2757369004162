@import '/src/assets/scss/global.scss';

.audit-page-container {
    @include modules-container;
	min-height: $min-heigth-container;
}

.internal-audits-title,
.generic-audits-title {
    border-bottom: solid 1px $grey
}