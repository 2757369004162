@import '/src/assets/scss/global.scss';

.modules-container {
	@include modules-container;
}

.navbar-vertical .navbar-vertical-content .navbar-nav .nav-link-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 1.8rem;
	max-width: 1.8rem;
}

.font{
	font-family: $font-family-sans-serif;
}