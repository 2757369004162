@import '/src/assets/scss/global.scss';

.controllo-accessi-container{
	min-height: $min-heigth-container;
	max-width: 1250px;
	margin: 0 auto;
}

.user-image {
	width: 200px;
	height: 200px;
}

.controlloAccessi__button__dettaglio{
	width: 230px;
	height: 40px;
	padding: 0;
}

.controllo-accessi-media{
	margin: 0 auto;
}

.mediaControlloAccessi-container{
	margin-left: 0;
}

@include media-breakpoint-down(xxl){
	.controlloAccessi__button__dettaglio{
		width: 22%;
	}
}

@include media-breakpoint-down(lg){
	.controlloAccessi__button__dettaglio{
		width: 100%;
	}
}

.controlloAccessi__button__dettaglio.red {
	background-color: $redControlloAccessi;
	border-color: $redControlloAccessi;
}

.controlloAccessi__button__dettaglio.red:hover {
	background-color: $redControlloAccessiBloccaHover;
	border-color: $redControlloAccessiBloccaHover;
}

.controlloAccessi__button__dettaglio.m-2.red:active {
	background-color: $redControlloAccessiBloccaHover;
	border-color: $redControlloAccessiBloccaHover;
}
