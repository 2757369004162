@import '/src/assets/scss/global.scss';

.confirm-popup-container{
	width: 20rem;

	.confirm-popup-buttons-container{
		display: flex;
		justify-content: flex-end;
		gap: 2rem;
	}
	.confirm-popup-buttons-container--center{
		margin: 0 auto;
		justify-content: space-between;
		width: 300px;
		padding: 10px 0px;
	}
	
	.confirm-popup-body{
		font-size: 1rem;
		@include black-text;
	}
	.confirm-popup-header{
		@include black-text;
		@include bold-text;
		font-size: 1.2rem;
	}
}