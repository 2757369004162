@import '/src/assets/scss/global.scss';

.journal-container {
	width: 60vw !important;

	@include media-breakpoint-down(md) {
        width: 85vw !important
    }
	@include media-breakpoint-up(xl) {
        width: 50vw !important
    }
}

.align-center{
	justify-content: center;
}

.journal-btn{
	@include module-page-title;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	height: 40px;
	width: $new-draft-button-width;
	margin-inline: ($module-state-width - $new-draft-button-width)/2;
	border-radius: 7px;
	&:hover{
		background-color: $blue;
	}

	@include media-breakpoint-up(xxl) {
		font-size: $font-size-md;
		width: $new-draft-button-width-xl;
		margin-inline: ($module-state-width-xl - $new-draft-button-width-xl)/2;
	}
}

.justify-end{
	justify-content: end;
}