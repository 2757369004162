@import '/src/assets/scss/global.scss';

/* global rules */
.first-upper {
    @include first-upper;
}

.capitalize {
    @include capitalize;
}

.rotate-45deg {
    transform: rotate(45deg);
}

.rotate-90deg {
    transform: rotate(90deg);
}

.rotate-180deg {
    transform: rotate(180deg);
}

.rotate-270deg {
    transform: rotate(270deg);
}

.generic-icon {
    height: 24px;
    width: 24px;
}

.blue-svg {
    @include blue-svg;
    path {
        @include blue-svg;
    }
}

.red-svg {
    @include red-svg;
    path {
        @include red-svg;
    }
}

.orange-svg {
    @include orange-svg;
    path {
        @include orange-svg;
    }
}

.grey-svg {
    @include grey-svg;
    path {
        @include grey-svg;
    }
}

.blue-text {
    @include blue-text;
}

.bold-text {
    @include bold-text;
}

.black-text {
    @include black-text;
}

.navbar-brand {
    img {
        width: 150px;
    }
    margin-right: auto;
}

.grey-rounded-card {
    @include grey-rounded-card;
}

.base-container {
    @include container;
}

.arrow-angle-up {
    transform: rotate(270deg)
}

.arrow-angle-down {
    transform: rotate(90deg)
}

.divider {
    display: flex;
    height: 5px;
    &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: $grey;
    }
}

.datetime-box {
    font-size: small;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .icon {
        margin-right: 5px;
        padding: 0;
        width: 28px;
        & path {
            fill: $datetime-icon-fill;
        }
    }
    .label, .day, .hour {
        display: inline-flex;
        font-size: $font-size-sm;
        @include bold-text;
        
        &.label {
            margin-right: 2px;
        }

        &.day, &.hour {
            @include blue-text;
        }
    }

    &.small {
        flex-basis: 44% !important;
        max-width: 4 * $min-tappable-area !important;
        &:first-of-type {
            margin-right: 4%;
        }
    }
}

.day {
    margin-inline: auto;
}

@include media-breakpoint-down(md) {  
    .day {
        margin-inline: initial;
    }
}