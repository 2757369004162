@import '/src/assets/scss/global.scss';

.box-details-wrapper {
    position: relative;
}

.accordion-box {
    @include shadow-rounded-box;
    background-color: $mid-grey;
    color: $datetime-color;
    font-size: $font-size-sm;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .accordion-type .card-accordion-type {
        @include blue-text;
        @include bold-text;
        font-size: $font-size-xl;
        padding: 2px 0;
        max-width: $min-tappable-area*1.5;
    }

    .accordion-details {
        padding: 5px;
        text-transform: capitalize;
    }


	.z-index-99 {
		z-index: 99;
	}

    .client-name {
        font-size: $font-size-md;
        text-transform: capitalize;
    }

    .arrow {
        cursor: pointer
    }
}

.box-modules-container {
	@include grey-rounded-card;
	@include shadow-rounded-box;
}

.box-details-container,
.box-details-accordion,
.box-open-report-container {
    max-width: $shift-details-container-width;
    font-family: $font-family-sans-serif;
}

.box-open-report-container {
    display: flex;
    justify-content: flex-end;
}

.box-details-accordion {
    &.small {
        @include custom-accordion();
    }
    &.standard {
        @include custom-accordion();
    }
}

.btn.open-report-button:not(.delivery),
.btn.open-report-button:not(.delivery):hover {
    background-color: $orange;
    border-color: $orange;
    white-space: nowrap;
}

@include media-breakpoint-up(lg) {
    .box-details-container,
    .box-details-accordion,
    .box-open-report-container {
        max-width: $container-width-md;
    }
}

@include media-breakpoint-up(xl) {
    .box-details-container,
    .box-details-accordion,
    .box-open-report-container {
        max-width: $container-width-lg;
    }
}

@include media-breakpoint-up(xxl) {
    .box-open-report-container {
        max-width: $container-width-lg + 400;
        position: absolute;
        top: -4rem;
    }
}

.z-index-99 {
	z-index: 99;
}

@include media-breakpoint-up(xxl) {
    .button-right.container-fluid {
        position: absolute;
        right: calc(2.5% - 10px);
        top: 15px;
		z-index: 99;
		width: 180px;
    }
}