@import '/src/assets/scss/global.scss';

.checklist-form-container {
	padding: 0;
	margin-bottom: 20px;
	@include black-text();

	.checklist-form-buttons-container {
		display: flex;
		gap: 50px;
		@include media-breakpoint-between(lg,xlg){
			gap: 250px;
		}
		@include media-breakpoint-between(md,lg){
			gap: 150px;
		}
		@include media-breakpoint-between(sm,md){
			gap: 100px;
		}
		position: sticky;
		bottom: 20px;
	}
	.submit-and-draft-buttons{
		width: 180px;
		height: 50px;
		font-family: $font-family-base;

		&:disabled {
			pointer-events: unset;
			cursor: not-allowed;
		}
	}
}