@import '/src/assets/scss/global.scss';

.accordion-box {
    min-height: 3/2*$min-tappable-area;

    .label::after {
        content: ':';
    }
}

.bold-text-cliente{
	font-weight: 700;
	color: $primary;
}

.customer-box-container {
    margin: 10px auto;
	padding-left: 0px;
    font-family: $headings-font-family;
    .info {
        min-height: $min-tappable-area;
    }
}


.box-details-accordion {
    .label {
        color: $mid-high-grey;
        min-width: 40%;
    }

    .datetime-box {
        .day, .hour {
            @include black-text;
            
        }

        .label, .day, .hour {
            display: inline-flex;
            font-size: $font-size-sm;
            @include bold-text;
            
            &.label {
                margin-right: 2px;
            }
        }
    }
}

.customer-box {
    justify-content: space-between; 
    align-items: center;
    cursor: pointer;
}
