@import '/src/assets/scss/global.scss';

.navbar-brand {
    margin-right: auto;
}

.text-logout{
	margin-top: 36px;
	color: $blue;
}

.bold{
	font-weight: 600;
}