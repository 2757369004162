@import '/src/assets/scss/global.scss';

.parcel-label  {
    color: $dark-grey;
}

.empty-search-result-container{
	display: flex;
	justify-content: center;
	align-items: center;
    height: 4*$min-tappable-area;
    text-align: center;
    label.parcel-label {
        width: 50%;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold !important;
    }
}

.container__elements__button{
	margin-bottom: 5px;
}

.container__elements__label{
	text-align: center;
}
