@import '/src/assets/scss/global.scss';

.card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.drop-down-row, 
.sidebar-menu-row {
	height: $min-tappable-area;
	line-height: $min-tappable-area;
	font-size: $font-size-sm;
    font-weight: $font-weight-medium;
	svg {
		margin-right: .2rem;
	}
}

svg {
	margin-right: $spacer/2;
}

.sidebar-menu-end {
	.card-body {
		margin:0;
		padding-left: 0;
		padding-right: 0;
	}
}