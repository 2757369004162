.privacyPageContainer{
	margin: 3% 6%;
	background: #e5e9e9;
	padding: 5%;
	border-radius: 2em;
	overflow: auto;
}

.clickForMoreDetalis{
	display: flex;
	gap: 20px;
	color: lightskyblue;
	cursor: pointer;
	margin: 10px;
	align-items: center;
	
	&:hover{
		color: blue;
	}
}

.acceptTerms{
	display: flex;
	gap: 20px;
	align-items: center;
	ol{
		padding-left: 0;
		padding-top: 10px;
		ul{
			padding-left: 0;
		}
	}
}

.buttonContainer{
	display: grid;
}

.buttonContainer{
	cursor: not-allowed;
}