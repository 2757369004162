@import '/src/assets/scss/global.scss';

.text-group-container {

	.text-container {
		width: 40%;
		font-family: $font-family-base;

		&::placeholder {
			font-family: $font-family-base;
		}

		@include media-breakpoint-down(sm){
			width: 100%;
		}
	}
}

.text-feedback-message {
	width: 100%;
	margin-top: 0.25rem;
	padding-left: 0;
	font-size: 75%;
	color: #e63757;
}