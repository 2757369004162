@import '/src/assets/scss/global.scss';

@include media-breakpoint-down(lg) {
    .container-fluid.auth-layout-container {
        padding: 0;
        margin: 0;

        .auth-layout-box {
            background-color: var(--#{$prefix}auth-bg);
            padding: 0;
            margin: 0;
        }

        .auth-layout-card {
            box-shadow: none;
            background-color: transparent;
        }

        input.form-control  {
            background-color: var(--#{$prefix}input-bg);
        }
    }
}

@include media-breakpoint-up(lg) {
    .container-fluid.auth-layout-container .auth-layout-card {
        background-color: var(--#{$prefix}auth-bg);
    }
}