$white : map-get($palette, 'white');
$grey : map-get($palette, 'grey');
$border-grey : map-get($palette, 'border-grey');
$light-grey : map-get($palette, 'light-grey');
$mid-grey : map-get($palette, 'mid-grey');
$dark-grey : map-get($palette, 'dark-grey');
$blue : map-get($palette, 'blue');
$yellow : map-get($palette, 'yellow');
$yellow_parcel : map-get($palette, 'yellow_parcel');
$green : map-get($palette, 'green');
$orange : map-get($palette, 'orange');
$orange_parcel : map-get($palette, 'orange_parcel');
$warning-red : map-get($palette, 'warning-red');

$mid-low-grey : map-get($palette, 'mid-low-grey');
$mid-high-grey : map-get($palette, 'mid-high-grey');
$datetime-color : map-get($palette, 'datetime-color');
$datetime-icon-fill : map-get($palette, 'datetime-icon-fill');
$font-black : map-get($palette, 'font-black');
$box-shadow-bootstrap-input-filds : map-get($palette , $key: 'box-shadow-bootstrap-input-filds');
$module-list-item-shadow : map-get($palette , $key: 'module-list-item-shadow');

$breakpoint-sm: map-get($grid-breakpoints, 'sm');

$padding-12 : 12px;
$padding-16 : 16px;
$min-tappable-area : 40px;
$header-footer-height : 80px;

$container-width-sm : 350px;
$container-width-md : 768px;
$container-width-lg : 800px;
$container-width-xl : 1200px;

$shift-details-container-width: 640px;
$modules-container-width-md : $shift-details-container-width;
$modules-container-width-lg : 800px;
$modules-container-width-xl : 1000px;

$module-state-width : 280px;
$module-state-width-xl : 340px;
$new-draft-button-width : 250px;
$new-draft-button-width-xl : 310px;
$min-heigth-container : calc(100vh - 2*$header-footer-height - 2*$min-tappable-area);
$patrol-read-cards-container-width: 340px;
$patrol-read-cards-container-width-xl: 400px;

$image-videos-pdf-preview-size: 420px;
