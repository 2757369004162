@import '/src/assets/scss/global.scss';

.module-journal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    
    .date-time {
        color: $font-black;
    }

    .status-start,
    .status-read,
    .status-in-progress,
    .status-draft {
        display: flex;
        justify-content: space-around;
        border: solid 1px $light-grey;
        border-radius: 7px;
        font-family: $headings-font-family;
        font-size: $font-size-sm;
        text-align: center;
        align-items: center;
        margin:10px 0;
        max-width: 70px !important;
        height: 30px;
        cursor: pointer;
        color: $font-black;
    }

    .status-start {
        background-color: $green;
        border-color: $green;
        color: $white;
        height: $min-tappable-area;
        max-width: 2.5*$min-tappable-area !important;
    }

    .status-read,
    .status-in-progress {
        background-color: $mid-low-grey;
        border-color: $mid-low-grey;
    }

    .status-draft {
        background-color: $yellow;
        border-color: $yellow;
    }

    @include media-breakpoint-up (xxl) {
        width: $module-state-width-xl;
        min-height: 70px;

        .date-time {
            font-size: $font-size-md;
        }
    }
}

.module-journal-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    
    .date-time {
        color: $font-black;
    }

    .status-start,
    .status-read,
    .status-in-progress,
    .status-draft {
        display: flex;
        justify-content: space-around;
        border: solid 1px $light-grey;
        border-radius: 7px;
        font-family: $headings-font-family;
        font-size: $font-size-sm;
        text-align: center;
        align-items: center;
        margin:10px 0;
        max-width: 70px !important;
        height: 30px;
        cursor: pointer;
        color: $font-black;
    }

    .status-start {
        background-color: $green;
        border-color: $green;
        color: $white;
        height: $min-tappable-area;
        max-width: 2.5*$min-tappable-area !important;
    }

    .status-read,
    .status-in-progress {
        background-color: $mid-low-grey;
        border-color: $mid-low-grey;
    }

    .status-draft {
        background-color: $yellow;
        border-color: $yellow;
    }

    @include media-breakpoint-up (xxl) {
        width: $module-state-width-xl;


        .date-time {
            font-size: $font-size-md;
        }
    }
}