@import "/src/assets/scss/global.scss";

.parcel-items-page-container {
	@include module-items-page-container;
	min-height: $min-heigth-container;
}

.align-center {
	justify-content: center;
}

.parcel-btn {
	@include module-page-title;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	height: $min-tappable-area;
	width: $new-draft-button-width;
	margin-inline: ($module-state-width - $new-draft-button-width)/2;
	border-radius: 7px;
	&:hover {
		background-color: $blue;
	}

	@include media-breakpoint-up(xxl) {
		font-size: $font-size-md;
		width: $new-draft-button-width-xl;
		margin-inline: ($module-state-width-xl - $new-draft-button-width-xl)/2;
	}
}

.justify-end {
	justify-content: end;
}

.newRecipient {
	height: 40px;
	font-family: $font-family-base;
	align-items: center;
}

.basic-single {
	min-width: 40%;
}

.new-item-container .checklist-question-title {
	min-width: 40%;
}

#parcelForm > .row {
	margin-top: 10px;
}

.select-field-container-checklist,
.form-text-area-container {
	min-width: 60%;
}

.parcel_item_container {
	border-bottom: 1px solid $gray-400;
}

.select-width {
	min-width: 60%;
}

@include media-breakpoint-down(md){
	.select-width {
		width: 85%;
	}
}

.parcel_dynamic_items_container {
	padding: $padding-16;
}