@import '/src/assets/scss/global.scss';

.contrainer_form__question {
	*, *::before, *::after{
		margin-top: 5px;
	}
}

.contrainer_form{
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.header_title{
	font-size: $h5-font-size;
	font-weight: 600;
}

.contrainer_form__question{
	display: flex;
	width: 300px;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
}

.contrainer_form__button{
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.text-group-container .text-container{
	width: 100%;
}

#newParcelUserForm .checklist-question-title {
	font-family: $font-family-sans-serif;
}