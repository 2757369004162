@import "/src/assets/scss/global.scss";

.parcel-items-page-container {
	@include module-items-page-container;
	min-height: $min-heigth-container;
}

.journal-container {
	width: 60vw !important;

	@include media-breakpoint-down(md) {
		width: 85vw !important;
	}
	@include media-breakpoint-up(xl) {
		width: 50vw !important;
	}
}

.align-center {
	justify-content: center;
}

.parcel-btn {
	@include module-page-title;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
	height: 40px;
	width: $new-draft-button-width;
	margin-inline: ($module-state-width - $new-draft-button-width)/2;
	border-radius: 7px;
	&:hover {
		background-color: $blue;
	}

	@include media-breakpoint-up(xxl) {
		font-size: $font-size-md;
		width: $new-draft-button-width-xl;
		margin-inline: ($module-state-width-xl - $new-draft-button-width-xl)/2;
	}
}

.justify-end {
	justify-content: end;
}

.newRecipient {
	height: 40px;
	font-family: $font-family-base;
	align-items: center;
}

.basic-single,
.checklist-question-title {
	min-width: 40%;
}

.accordion-box > .accordion-type > .card-accordion-type {
	font-size: 1rem;
	max-width: none;
}

.operator-div {
	word-spacing: 1px;
}

.box-details-container {
	margin: 0 auto;
}

.select-field-container-checklist,
.form-text-area-container {
	min-width: 50%;
}

.form-select-container {
	min-width: 50%;
}

.space-between {
	justify-content: space-between;
}


.modal-footer {
	border-top: none !important;
}

.select-width-50 {
	min-width: 50%;
}

@include media-breakpoint-down(md){
	.select-width-50 {
		width: 85%;
	}
}