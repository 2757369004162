@import '/src/assets/scss/global.scss';

.report-section-container {

	.report-section-title {
		@include section-title;
	}

	.report-questions-container {
		.report-single-question-container {
			label{
				@include bold-text-labels;
				font-size: $font-size-md;
			}
		}

		.report-answer {
			font-size: $font-size-md;
		}

		.report-answer-blue-text{
			color: $blue;
		}
	}
}

.hr-style {
	margin: 15px 0 4px 0;
}