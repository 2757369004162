@import "/src/assets/scss/global.scss";

.signature-container {
	@include module-items-page-container;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 0;
}

// .signature-container{
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(2,1fr);
// };

.sigPadContainer {
	// background-color: rgb(238, 235, 235);
	padding: 0 10px 10px;
	margin-bottom: 1rem;
	border: 2px solid #777;
	border-radius: 20px;
}

.signature-pad {
	height: 20rem;
	border: 2px solid #777 !important;
	margin-bottom: 1rem;
}

.sigCanvas {
	width: 100%;
	height: 20rem;
}

.disabled-btn {
	background-color: #a0a0a0;
}

.disabled-btn:hover {
	background-color: #767676;
}

.button-group {
	display: flex;
	gap: 1rem;
	justify-content: center;
}

.button-group button {
	border-radius: 3px;
}

.rendering {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signature {
	display: inline-block;
	width: 80%;
}

.signature-container .signatureButton {
	height: 30px;
	width: 30px;
	border-radius: 5px;
	box-shadow: 0px 0px 1px;
	background-color: $light-grey;
}

.disabled-background {
	background-color: $mid-low-grey;
}
