@import "/src/assets/scss/global.scss";

.row-container-search {
	align-items: center;
	justify-content: space-between;
	margin-block: 40px;
}

.operators_table__container {
	overflow-x: auto;
}

.operators__nav_element {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: $blue;
	color: $white;
	min-width: 500px;
	padding: 10px 20px 10px 0;
	border-radius: 10px;
	font-weight: $font-weight-bold;
}

.operators__nav_element__single {
	text-align: center;
	width: 300px;
}

.operators__nav_element__single_small {
	text-align: center;
	width: 100px;
}

.operators__item_element {
	align-items: center;
	display: flex;
	justify-content: space-between;
	min-width: 500px;
	padding: 20px;
	padding-left: 0;
	border-bottom: 2px solid $mid-low-grey;
}

.operators__item_element__single {
	width: 300px;
	display: block;
	overflow: hidden;
	text-align: center;
	display: flex;
	justify-content: center;
}

.operators__item_element__single_small {
	width: 100px;
	display: block;
	overflow: hidden;
	text-align: center;
	display: flex;
	justify-content: center;
}

.operators__item_element__single__selected_operator {
	color: $green;
}

.operators-search-control-form {
	height: 40px;
}

.operators__font {
	font-size: 14px;
}

.operators__headers_font {
	font-family: $font-family-sans-serif;
	font-size: 16px;
}

@include media-breakpoint-between(xs, md) {
	.operators__font {
		font-size: 10px;
	}

	.operators__headers_font {
		font-size: 12px;
	}

	.operators__nav_element {
		border-radius: 5px;
		font-size: 10px;
	}

	.operators-search-control-form {
		margin-bottom: 8px;
	}
}

@include media-breakpoint-between(md, lg) {
	.operators__font {
		font-size: 11px;
	}

	.operators__headers_font {
		font-size: 14px;
	}

	.item_element__single__type {
		height: 30px;
	}
}

.key-label  {
    color: $dark-grey;
}

.empty-search-result-container{
	display: flex;
	justify-content: center;
	align-items: center;
    height: 4*$min-tappable-area;
    text-align: center;
    label.key-label {
        width: 50%;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold !important;
    }
}

.operators-button-content{
    display: flex;
    justify-content: end;
	margin-right: 16px;
    .submit-button-operators-form {
        min-width: 150px;
		height: 40px;
    }
}