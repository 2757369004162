@import '/src/assets/scss/global.scss';

.checklist-items-page-container {
	@include module-items-page-container;
	min-height: $min-heigth-container;

	.start-new-draft-button{
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		height: 40px;
		width: $new-draft-button-width;
		margin-inline: ($module-state-width - $new-draft-button-width)/2;
		border-radius: 7px;
		margin-bottom: $min-tappable-area;


		@include media-breakpoint-up(xxl) {
			font-size: $font-size-md;
			width: $new-draft-button-width-xl;
			margin-inline: ($module-state-width-xl - $new-draft-button-width-xl)/2;
		}
	}

	.start-new-draft-button--start{
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		height: 40px;
		width: $new-draft-button-width;
		border-radius: 7px;
		margin-bottom: $min-tappable-area;

		@include media-breakpoint-up(xxl) {
			font-size: $font-size-md;
			width: $new-draft-button-width-xl;
		}
	}

	.sticky-button-container {
		position: sticky;
		bottom: $min-tappable-area;
	}
}