@import '/src/assets/scss/global.scss';

.incorrectPassword {
	min-height: 24px;
	margin-bottom: 10px;
	font-family: var(--#{$prefix}-font-sans-serif);
	font-weight: var(--font-weight-500);
	p {
		margin: 0;
	}
}

.borderRightZero {
	border-right: 0 !important;
}

.eye-icon {
	width: 25px;
}

.icon-button-password{
	position: absolute;
	right: 0;
	top: 7px;
	z-index: 99;
}