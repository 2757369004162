@import "/src/assets/scss/global.scss";

.align-mid {
	align-items: center;
}
.row > .row-container {
	padding: 0;
}
.items-container > .search-button {
	height: 38px;
	width: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--sicurforce-bg-navbar-glass);
}

.select-w60{
	width: 60%;
}

.red-color{
	color: $red;
}


.select__single-value > div > .company-info {
	display: none;
}
.select__single-value > div {
	border-bottom: none;
	margin: 0;
}

.select__control > .select__indicators {
	display: none;
}

.select__single-value,
.select__value-container {
	margin: 0;
}

.basic-single > .select__control {
	background-color: var(--sicurforce-bg-navbar-glass);
	margin: 0;
}

.row-container > .items-container {
	column-gap: 10px;
}

.basic-single > .select__control {
	border-radius: 10px;
	box-shadow: 0px 1px 3px 0px;
}

.basic-single {
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.select__menu-list > .select__option {
	padding: 0;
}

.basic-single > .select__menu {
	border-radius: 12px;
}

.options-container {
	font-family: $font-family-sans-serif;
	font-size: map_get($font-sizes, "-1");
	color: $font-black;
	margin: 0px 20px;
	border-bottom: 1px solid;
	padding: 12px 0;
	border-color: $mid-high-grey;
	font-weight: 500;
}

.select__option:last-child > .options-container {
	border-bottom: none;
}

.allinea_container_end {
	align-items: flex-end;
}