@use 'sass:math';

@mixin first-upper {
    text-transform: lowercase;
    &:first-letter {
        text-transform: uppercase;
    }
}

@mixin capitalize {
    text-transform: capitalize;
}

@mixin blue-svg {
    fill: $blue;
}

@mixin red-svg {
    fill: $red;
}

@mixin orange-svg {
    fill: $orange;
}

@mixin grey-svg {
    fill: $datetime-icon-fill;
}

@mixin blue-text {
    color: $blue;
}

@mixin black-text {
    color: $font-black;
}

@mixin bold-text {
    font-weight: 600;
}

@mixin bold-text-labels {
    font-weight: 600!important;
}

@mixin section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 136px;
    font-size: $font-size-sm;
    width: fit-content;
    padding: 1px 10px;
    margin-bottom: 20px;
    color: $dark-grey;
    border: 1px solid $grey;
    border-radius: 11px;
    background-color: $mid-low-grey;
    color: $datetime-color;
    @include bold-text;
}

@mixin grey-rounded-card {
    background-color: $mid-low-grey;
    color: $datetime-color;
    border-radius: 15px;
}

@mixin shadow-rounded-box {
    border: solid 1px $light-grey;
    border-radius: 20px;
    box-shadow: 1px 2px 2px 1px $grey;
    background-color: $mid-grey;
}

@mixin container {
    max-width: $container-width-md;

    @include media-breakpoint-up(md) {
        max-width: $container-width-md
    }

    @include media-breakpoint-up(lg) {
        max-width: $container-width-lg
    }

    @include media-breakpoint-up(xl) {
        max-width: $container-width-xl
    }
}

@mixin modules-container {
    max-width: $modules-container-width-md;

    @include media-breakpoint-up(lg) {
        max-width: $modules-container-width-lg
    }

    @include media-breakpoint-up(xl) {
        max-width: $modules-container-width-xl
    }
}

@mixin modules-item {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
        justify-content: center;
        &:first-child {
            margin-top: 1rem;
        }
    }

    @include media-breakpoint-up(md) {
        width: calc(100% / 2);
        max-width: calc(100% / 2);
        min-width: calc(100% / 2);
    }

    @include media-breakpoint-up(lg) {
        width: calc(100% / 3);
        max-width: calc(100% / 3);
        min-width: calc(100% / 3);
    }
}

@mixin custom-accordion() {
    overflow: hidden;
    max-height: 0;
    transition: max-height .5s ease-in-out;
    
    .fade-content {
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
    
    &.open {
        max-height: 9999px;
    
        .fade-content {
            opacity: 1;
        }
    }
}

@mixin module-page-title {
	width: fit-content;
	padding: 2px 25px;
	background-color: $primary;
	border-radius: 11px;
	color: $white;
	font-family: $font-family-base;
	@include bold-text;
}

@mixin module-items-page-container {
	.items-container {
        display: flex;
		flex-wrap: wrap;
        margin: 0 auto;
        max-width: $module-state-width;
		// row-gap: $min-tappable-area/2;
		column-gap: $min-tappable-area*2;
		margin-top: $min-tappable-area;
		margin-bottom: $min-tappable-area;

        @include media-breakpoint-up (md) {
            max-width: 2*($module-state-width + $min-tappable-area);
        }
    
        @include media-breakpoint-up (lg) {
            max-width: 3*($module-state-width + $min-tappable-area);
        }

        @include media-breakpoint-up (xxl) {
            max-width: 3*($module-state-width-xl + $min-tappable-area);
        }
	}
}