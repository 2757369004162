
@import '/src/assets/scss/global.scss';

.textarea-input-container{
	font-family: $font-family-base;

	&::placeholder{
		font-family: $font-family-base;
	}
}

.journalTextArea{
	height: 300px;
}

.form-text-area-container {
	.journalTextArea{
		height: 300px;
	}
}