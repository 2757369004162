@import '/src/assets/scss/global.scss';

.notes .datetime-box .text-note{
    @include black-text;
}

.shift-time-container {
	margin: 10px auto;
	padding-left: 0px;
    font-family: $headings-font-family;
    .info {
        min-height: $min-tappable-area;
    }
}

.box-details-accordion {
    .label {
        color: $mid-high-grey;
        min-width: 40%;
    }

    .datetime-box {
        .day, .hour {
            @include black-text;
        }

        .label, .day, .hour {
            display: inline-flex;
            font-size: $font-size-sm;
            @include bold-text;
            
            &.label {
                margin-right: 2px;
            }
        }
    }
    .shift-notes {
        font-size: $font-size-md;
        max-height: 185px;
        overflow-y: auto;
    }
}

.shift-box {
    justify-content: space-between; 
    align-items: center;
    cursor: pointer;
}

.color_link {
    color: $green;
}