@import '/src/assets/scss/global.scss';

.controllo-accessi-dettaglio__photo{
	width: 100%;
	height: 100%;
	background: $blue;
	border-radius: 100%;
}


.text_dettaglio_label{
	font-size: 17px;
	font-weight: 400;
	margin: 0;
}

.text_dettaglio_value{
	color: $font-black;
	font-size: 17px;
	font-weight: 900;
}

.text_dettaglio_titolo{
    color: $blue;
    font-size: 1.1rem;
}

.controllo-accessi-box__text{
	word-break: break-all;
}

.controllo-accessi-dettaglio .dettaglio-utente div {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	padding-bottom: 0.3rem;
}

.controllo-accessi-dettaglio__profilePic_container {
	border-radius: 50%;
	width: 200px;
	height: 200px;
	overflow: hidden;
	cursor: pointer;
}

.controllo-accessi-dettaglio__profilePic_img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.text_dettaglio_status__circle {
	height: 20px;
	width: 20px;
	border-radius: 100%;
	background: #000;
	margin-right: 10px;
	margin-top: 2px;
}

.text_dettaglio_status__circle_green{
	background: $green;
}

.text_dettaglio_status__circle_orange{
	background: $orangeControlloAccessi;
}

.text_dettaglio_status__circle_red {
	background: $redControlloAccessi;
}

.controllo-accessi-dettaglio__status_container{
	width: 100%;
	margin-right: 50px;
}

.text_dettaglio_status{
	font-weight: 700;
	color: $font-black;
}



@include media-breakpoint-up(lg) {
	.controllo-accessi-dettaglio__status_container{
		width: 100%;
		margin-right: 150px;
	}
}

@include media-breakpoint-up(sm) {
	.controllo-accessi-dettaglio__status_container{
		width: 100%;
		margin-right: 50px;
	}
}