@import '/src/assets/scss/global.scss';

footer {
    height: $header-footer-height;
    background-color: $bg-navbar-glass;
    padding-left: 5%;
    @include blue-text;
    @include bold-text;

    .app-version.production {
        opacity: 0;
    }
}