@import "/src/assets/scss/global.scss";

$font-size-lg: 1rem;
$font-size-md: 0.875rem;
$font-size-sm: 0.75rem;

.item_element__single_modal {
  font-size: $font-size-sm;

  .form-text-area-container {
    .text-group-container {
      input,
      textarea {
        font-size: 0.75rem;
      }

      @include media-breakpoint-down(md) {
        display: block;
        gap: 0.5rem;
      }
    }
  }
  .checklist-question-title {
    min-width: 15%;
    font-weight: 400 !important;
  }

  // Added responsiveness for mobile
  @include media-breakpoint-down(md) {
    flex-direction: column; 
    gap: 0.5rem;
  }
}

.item_element_single_operator_name {
  padding-left: 0.75rem !important;
}

.datepicker_timbrature_page {
  .date-picker-group-container {
    .react-datepicker__input-container {
      display: flex;
      justify-content: flex-end;
      input {
        width: 100% !important;
      }
    }
    .date-picker-container {
      font-size: 0.75rem;
    }
  }
}

.select_timbrature_page {
  select {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
}

.timbrature_modal_button_submit {
  background-color: #00a898 !important;
  border: none !important;
}

.table-header {
  background-color: $blue;
  color: $white;
  font-weight: bold;
  padding: 1rem;
  border-radius: 0.625rem;
}

.table-row {
  background-color: #fff;
  border-bottom: 2px solid $border-grey;
}

.status-circle {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;

  &.finished {
    background-color: $green;
  }
  &.pending {
    background-color: #dc3545;
  }
  &.in_progress {
    background-color: $yellow;
  }
  &.manual {
    background-color: $white;
    border: 2px solid $green;
  }
}

.d-flex-table {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-divider {
  border: none;
  border-top: 1px solid $border-grey;
  margin: 0.5rem 0;
}

@include media-breakpoint-down(lg) {
  .responsive-text {
    font-size: $font-size-md;
  }
}

@include media-breakpoint-down(md) {
  .responsive-text {
    font-size: $font-size-sm;
  }
}

// @include media-breakpoint-down(sm) {
//   .responsive-text {
//     font-size: $font-size-sm;
//   }
// }
