@import '/src/assets/scss/global.scss';

.documentDetail_container {
    display: flex;
    align-items: center;
}

.documentDetail_icon {
    flex: 0 0 auto;
    width: 5%;
}

.documentDetail_text {
    width: calc(65% - 4px);
    text-align: start;
    line-height: 1.2;
}

.documentDetail_text__title {
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.documentDetail_text__subtitle{
    font-size: 14px;
}

.documentDetail_date {
    width: calc(15% - 4px);
    text-align: center;
    font-size: 14px;
}

.documentDetail_download {
    display: flex;
    width: 10%;
    justify-content: flex-end;
    flex: 0 0 auto;
    cursor: pointer;
}

@media (min-width: 769px) and (max-width: 1300px) {
    .documentDetail_icon {
        width: 5%;
        margin-right: 8px;
    }

    .documentDetail_text {
        width: calc(60% - 4px);
    }

    .documentDetail_text__title {
       font-size: 14px;
    }

    .documentDetail_text__subtitle{
        font-size: 12px;
    }

    .documentDetail_date{
        width: calc(20% - 4px);
        font-size: 12px;
    }

    .documentDetail_download {
        width: 15%;
    }

    .documentDetail_labelFont {
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    .documentDetail_icon {
        width: 10%;
        margin-right: 8px;
    }

    .documentDetail_text {
        width: calc(45% - 4px);
    }

    .documentDetail_text__title {
       font-size: 14px;
    }

    .documentDetail_text__subtitle{
        font-size: 12px;
    }

    .documentDetail_date{
        width: calc(35% - 4px);
        font-size: 12px;
    }

    .documentDetail_download {
        flex-direction: column;
        width: 10%;
    }
}