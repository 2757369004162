@import '/src/assets/scss/global.scss';

.sicurforce-fullpage-spinner-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	div {
		max-width: 12rem;
	}
}