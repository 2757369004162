@import '/src/assets/scss/global.scss';

.checkbox-question-container {
	.was-validated{
		color: $font-black;
	}

	.form-check-label {
		font-family: $font-family-base;
		@include bold-text-labels;
	}

	.form-check-input .is-valid .form-check-label {
		color: $font-black;
	}

	.feedback-message {
		width: 100%;
		margin-top: 0.25rem;
		padding-left: 0;
		font-size: 75%;
		color: #e63757;
	}

	.checkboxes-container,
	.question-container {
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;
		.form-check-label {
			padding-inline: 2dvw;
		}

		.checkbox-square {
			&>label{
				color:black;
			}
			
			input.form-check-input.is-valid{
				&:checked {
					background-color: $blue;
					border-color: $blue;
				}
			}

			.was-validated input.form-check-input.is-valid {
				background-color: $white;

				&:checked {
					background-color: $blue;
				}
			}
		}
	}
}