@import '/src/assets/scss/global.scss';

.container-parcel-label{
	height: 70vh;
	margin: 0 auto;
}

.container__elements{
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	
    label.parcel-label {
		height: 4*$min-tappable-area;
		margin-top: $min-tappable-area;
        width: 80%;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold !important;
    }
}

.container__elements__button{
	margin-bottom: 5px;
}

.container__elements__label{
	text-align: center;
}

