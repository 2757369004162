@import '/src/assets/scss/global.scss';

.checklist-report-container {
	padding: 0;
	margin-bottom: 20px;
	margin-top: 40px;
	@include black-text();
}

.section-container-margin {
	margin: 0;
	margin-top: 20px;
}