@import '/src/assets/scss/global.scss';

.accordion-details {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-large{
	height: 100%;
	padding: 0;
}

.button_details{
    width: 180px;
}


.grey-text{
	color:#9E9E9E;
}

.blue-text-additional{
	color: $blue;
}

@include media-breakpoint-up (md) {
	.box_parcel_element {
		padding-left: 8px;
	}	
}



