@import '/src/assets/scss/global.scss';

.section-container {
	margin: 3rem calc(-0.5 * var(--sicurforce-gutter-x));
	
	.section-title {
		@include section-title;
	}

	.questions-container {
		margin-bottom: 20px;
		margin-top: 10px;

		.form-item-container {
			margin-top: 10px;
			padding: 0;
		}
	}
}