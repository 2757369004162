@import "/src/assets/scss/global.scss";

.title {
	font-size: 12px;
	font-weight: 700;
	color: $mid-high-grey;
}

.title_value {
	font-size: 12px;
	color: $black;
}

b {
	color: $dark-grey;
}

.element_consultation {
	padding-right: 3rem;
	padding-left: 3rem;
}

.element_consultation__left__content p {
	margin-bottom: 0.5rem;
	&.title_value {
		margin-left: 2px;
	}
}

.element_consultation__right__content p {
	margin-bottom: 0.5rem;
	&.title_value {
		margin-left: 2px;
	}
}

.element_consultation {
	border-radius: 15px;
	background: $mid-grey;
	margin: 0;
	display: flex;
	box-shadow: 1px 2px 2px 1px $grey;
}

.element_container__label {
	max-width: 300px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.element_consultation__left {
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 60%;
	display: inline-block;
}

.element_consultation__right {
	width: 40%;
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	display: inline-block;
}

.element_consultation__left__lost {
	padding: 13px 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 60%;
	display: inline-block;
}

.element_consultation__right__lost {
	width: 40%;
	padding: 13px 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	display: inline-block;
}

.element_consultation__left__content__title {
	display: inline-flex;
	display: flex;
}

.element_consultation__left__content {
	text-align: start;
	align-items: center;
	font-size: 11px;
	display: flex;
	flex-wrap: wrap;
}

.element_consultation__right__content {
	text-align: start;
	align-items: center;
	font-size: 11px;
	display: flex;
	flex-wrap: wrap;
}

@include media-breakpoint-between(xs, md) {
	.title {
		font-size: 12px;
		font-weight: 700;
	}

	.title_value {
		font-size: 12px;
		color: $black;
	}

	.element_consultation__left {
		padding: 10px 10px;
	}

	.element_consultation__right {
		padding: 10px 10px;
	}

	.element_consultation__left__lost {
		padding: 10px 10px;
	}

	.element_consultation__right__lost {
		padding: 10px 10px;
	}

	.element_container__label {
		font-size: 14px;
	}

	.element_consultation {
		max-width: 640px;
	}
}

.parcel-consultation-box {
	font-family: $headings-font-family;
}
