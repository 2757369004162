@import '/src/assets/scss/global.scss';

.background-grey-important {
    background-color: $light-grey !important;
}

.background-grey-important:focus {
    background-color: initial !important;
}

.background-grey-important::placeholder{
    color: $blue !important;
}