@import '/src/assets/scss/global.scss';

.report-container {
	@include modules-container;
}


.internal-report-title,
.generic-report-title {
    border-bottom: solid 1px $grey
}

.bold-text-report{
	font-weight: 700;
}