@import '/src/assets/scss/global.scss';

.label-left {
	color: $mid-high-grey;
	min-width: 20%;
}

@include media-breakpoint-up(md) {
	.margin-inline {
		margin-inline: auto;
	}
	
}
