@import '/src/assets/scss/global.scss';

.areaDocumenti_container{
	min-height: $min-heigth-container;
	max-width: 1250px;
	width: 70%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding: 32px 16px;
}

.areaDocumenti_title {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.areaDocumenti_separatore {
	width: 90%;
	margin: 0 auto;
}

.intestazione {
	color: $gray-800;
	font-weight: 800;
}

@media screen and (max-width: 792px) {
	.areaDocumenti_container{
		padding: 0;
		width: 85%;
	}

	.areaDocumenti_title {
		flex-basis: 100%;
	}

	.areaDocumenti_title__filter {
		padding: 10px 0;
	}

	.areaDocumenti_separatore {
		width: 100%;
	}

	.intestazione {
		color: $gray-800;
		font-size: 12px;
	}
}


