@import '/src/assets/scss/global.scss';

.checklist-question-title {
	display: block;
	font-weight: 500;
	font-family: $font-family-base;
}

label.checklist-question-title.form-label {
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

.title-width {
	width: 60vh;
}