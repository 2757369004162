@import "/src/assets/scss/global.scss";

.row-container-search {
	align-items: center;
	justify-content: space-between;
	margin-block: 40px;
}

.nav_element {
	display: flex;
	justify-content: space-between;
	background: $blue;
	color: $white;
	padding: 10px 20px;
	border-radius: 10px;
	font-weight: $font-weight-bold;
}

.nav_element__single {
	text-align: center;
	width: 300px;
}

.container__search_button {
	display: flex;
	justify-content: space-between;
	height: 50px;
	margin-block: $min-tappable-area;
	align-content: center;
	align-items: center;
}

.item_element {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	border-bottom: 2px solid $mid-low-grey;
}

.item_element__single {
	width: 300px;
	display: block;
	overflow: hidden;
	text-align: center;
	display: flex;
	justify-content: center;
}

// .item_element__single__type {
// 	width: 150px;
// 	padding: 2px;
// 	border-radius: 10px;
// }
.item_element__single__key_stored {
	background: $green;
	color: $white;
}
.item_element__single__consegnato {
	background: $dark-grey;
	color: $white;
}
.item_element__single__key_delivered {
	background: $yellow_parcel;
	color: $white;
}
.item_element__single__lost {
	background: $orange_parcel;
	color: $white;
}
.item_element__single__stolen {
	background: $orange_parcel;
	color: $white;
}
.item_element__single__cancelled {
	background: $orange_parcel;
	color: $white;
}

@include media-breakpoint-between(xs, md) {
	.nav_element {
		padding: 5px 10px;
		border-radius: 5px;
		font-size: 10px;
	}

	// .nav_element__single p {
	// 	width: 150px;
	// }

	.sizeb {
		font-size: 10px;
	}

	.item_element__single__type {
		height: 20px;
		font-size: 10px;
	}

	// .submit-button{
	// 	height: 30px;
	// 	width: 110px;
	// 	font-size: 10px!important;
	// 	text-align: start;
	// }
	.submit-button p {
		width: 100%;
	}
}

@include media-breakpoint-between(md, lg) {
	.item_element__single__type {
		height: 30px;
	}
}

.submit-button-keys {
	min-width: 210px;
	height: 36px;
}
.align-left {
	text-align: left;
}


.key-label  {
    color: $dark-grey;
}

.empty-search-result-container{
	display: flex;
	justify-content: center;
	align-items: center;
    height: 4*$min-tappable-area;
    text-align: center;
    label.key-label {
        width: 50%;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold !important;
    }
}

.container-key-label{
	height: 70vh;
	margin: 0 auto;
}

.container__elements__label > .key-label {
	font-weight: 600 !important;
}