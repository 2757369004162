@import '/src/assets/scss/global.scss';

.controlloAccessi__button {
    display: flex; /* Abilita il layout flex */
    align-items: center; /* Centra verticalmente il contenuto */
    justify-content: center;
    height: 40px;
}

.error_field {
   color: $redControlloAccessi; 
}

.controlloAccessi__filters_container {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.controlloAccessi__filters_item {
	width: calc(50% - 10px);
	margin: 5px;
}

.button_element_center{
	justify-content: center;
}

.controllo-accessi-box{
	background: $gray-550;
	border-radius: 10px;
	border: solid 1px $gray-f5;
	box-shadow: 1px 2px 2px 1px $grey;
	font-size: 0.8rem;
	height: 100%;
}

@media screen and (max-width: 768px) {
	.controlloAccessi__filters_item {
		width: calc(100% - 16px);
	}

	.button_element_center{
		justify-content: flex-start;
	}
}

@media (min-width: 991px) and (max-width: 1539px) {
    .controlloAccessi__button.btn {
        font-size: 14px;
        white-space: normal; /* Impedisce il taglio delle parole */
        line-height: 1.2; /* Imposta l'interlinea */
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .controlloAccessi__button.btn {
        font-size: 14px;
        white-space: normal; /* Impedisce il taglio delle parole */
        line-height: 1.2; /* Imposta l'interlinea */
        display: flex; 
        align-items: center; 
        justify-content: center;
    }
}